import React from 'react';
import './business.css'
import Header from "../../component/header";
import Footer from '../../component/footer';
import Bus080Info from '../../component/Business/Business_080_info';

export default function Business_080() {
  return (
    <div className="business">
      <Header/>
      <Bus080Info/>
      <Footer/>
    </div>
  )
}
