import React from 'react';
import './pro_call.css';
import Header from "../../component/header";
import Footer from '../../component/footer';
import Call_info from '../../component/Product/call_info';
export default function Pro_call() {
  return (
  <div className="pro_call">
    <Header/>
    <Call_info/>
    <Footer/>
  </div>
  )
}
