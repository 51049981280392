import React from 'react';
import './company_info.css';
import Map from './Map';

export default function Company_info_map() {
  return (
    <div className="company_info_map">
        <div className="company_map">
        <div className="map_title">
        <h2>오시는 길</h2>
        </div>
        <Map id="company_map_component" />
        <div className="map_info">
        <h6>주소 : 대구광역시 달서구 월배로34길 17 KT달서지사3층 </h6>

        <h6>대구 1호선 월배역 도보 5분</h6>
        </div>

        

      </div>
    </div>
  )
}
