/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useEffect,useState } from 'react';
import './message_info.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
export default function message_info() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImg, setCurrentImg] = useState("");
  const index = 3;
  const messageInfoImgData = ["proImage/알림톡 제안서_제이에스정보통신.png", "proImage/메세지사진.jpg","proImage/메세지사진.jpg","proImage/메세지사진.jpg"]
  const messageInfoTitle = ["1. 알림톡 서비스", "2. SMS서비스", "3. MMS서비스" ,"4. FMS서비스"]
  const messageInfoContent = ["알림톡 서비스는 정보성 메시지를 카카오톡 친구 추가 과정 없이 플러스친구 아이디를 통해 카카오톡 사용자에게 발송하는 기업 메시징 서비스 입니다.",
"SMS(Short Message Service,단문 문자서비스)는 이동전화에 문자를 전달하는 서비스로 단문 엔터키(Short Message Entity:SME)등과 연계하여 이동전화 가입자가 문자형태의 단문을 착,발신 할수 있게 하는 부가 서비스입니다.",
"MMS는 장문 문자서비스(TEXT 2,000자)와 멀티미디어(장문,오디오,이미지,동영상)로 전달하는 부가 서비스입니다.",
"FMS서비스는 팩스메세지 부가 서비스입니다."]


  useEffect(()=>{
    console.log(currentIndex)

  },[currentIndex])
  
  const onClickLeft = () => {
    if(currentIndex !== 0){
      setCurrentIndex(currentIndex-1);
    }
    else setCurrentIndex(index);
  }

  const onClickRigth = () => {
    if(currentIndex !== index){
      setCurrentIndex(currentIndex+1);
    }
    else setCurrentIndex(0);
  }
  

  return (
    <div className="">
      {/* 헤더 */}
      <div className="messageInfo_header">
        <h2>제품소개</h2>
         <div className="message_info_table" role="navigation">
          <nav id="message_info_nav">
          <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
          <Link to ="/product_hos"><a className="nav-link" href="#" style={{color:'black'}}>진료안내</a></Link>
          </li>
          <li className="nav-item">
          <Link to ="/product_ms"> <a className="nav-link  active" id="bus_active_btn" style={{color:'black'}}>문자서비스</a></Link>
          </li>
          </ul>
        </nav>
        </div>
      </div>
      {/* 헤더 끝 */}
     <div className="messageInfo_content">
      <div className="messageInfo_card"  style={{ marginBottom : '5%'}}>

      <Card style={{ width: '100vmin', display:'flex', margin: 'auto'}}>
      <Card.Header style={{ textAlign : 'center' }}><h5>서비스 설명 및 예시</h5></Card.Header>
      <Card.Body >
      {/* <Button variant="outline-secondary" onClick={onClickLeft}>left</Button> */}
      <Stack direction="horizontal" gap={3} style={{justifyContent : "space-around"}}>
      <Button variant="outline-secondary" onClick={onClickLeft}>left</Button>
      <Card.Img variant="top" src= {messageInfoImgData[currentIndex]} 
      style={{ width: '65vmin', height: '30vmax',display:'flex', margin: 'auto'}}/>
     <Button variant="outline-secondary"  onClick={onClickRigth}>right</Button>
      </Stack>

        <Card.Title style={{fontSize : '1.5em'}}> {messageInfoTitle[currentIndex]}</Card.Title>
        <Card.Text style={{fontSize : '1em'}}>
        {messageInfoContent[currentIndex]}
        </Card.Text>
        <div className="messageInfo_index" style={{ textAlign : 'center' , margin : '10px'}}>

      </div>
      </Card.Body>
    </Card>
      </div>
    </div>
  
  
    </div>
  )
}