/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import './hos_info.css';
import { Link } from 'react-router-dom';
import { useEffect,useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

export default function hos_info() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const index = 7;
  const hosImageData = ["proImage/hosImage/진료안내1.PNG","proImage/hosImage/진료안내2.PNG","proImage/hosImage/진료안내3.jpg","proImage/hosImage/진료안내4.png","proImage/hosImage/진료안내5.png",
"proImage/hosImage/진료안내6.jpg","proImage/hosImage/진료안내7.png","proImage/hosImage/진료안내8.png"]
const hosTitleData = ["1. [진료대기] [결과대기]  안내","2.[예약대기] [공지사항] 안내", "3. [수술중] [회복중] 안내", "4. 내시경 안내",
"5. 응급의료센터 종합현황", "6. 조제 안내", "7. 진료실 룸 관리", "8. 외래 내원 환자 접수 관리"]
  const onClickLeft = () => {
    if(currentIndex !== 0){
      setCurrentIndex(currentIndex-1);
    }
    else setCurrentIndex(index);
  }

  const onClickRigth = () => {
    if(currentIndex !== index){
      setCurrentIndex(currentIndex+1);
    }
    else setCurrentIndex(0);
  }
  return (
    <div className="hos_info">
        <div className="hosInfo_header">
        <h2>제품소개</h2>
         <div className="hos_info_table" role="navigation">
          <nav id="hos_info_nav">
          <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
          <Link to ="/product_hos" >< a className="nav-link  active" id="bus_active_btn" style={{color:'black'}}>진료안내</a></Link>
          </li>
          <li className="nav-item">
          <Link to ="/product_ms"> <a className="nav-link " style={{color:'black'}}>문자서비스</a></Link>
          </li>
          </ul>
        </nav>
        </div>
      </div>
      <div className="hosInfo_content">
        <div className="hosInfo_accordion" style={{marginBottom: '3%'}}>
        <Card style={{width: '100vmin', display:'flex', margin: 'auto'}}>
      <Card.Header><h5>진료 대기 안내 서비스는</h5></Card.Header>
      <Card.Body>
        <blockquote className="blockquote mb-0">
            {' '}
            <p style={{fontSize:'1rem'}}>
            의료정보(OCS,EMR) DB를 본 솔루션과 연동하여 고객의 예약/진료/검진/기타정보를 디스플레이 장치를 통해 실시간으로
   안내함으로써 고객으로부터 진료순서에 대한 투명성 확보,
   대기 시간 단축을 통한 고객 만족도 향상,
   담당자의 업무처리 간소화 등 최고의 의료서비스를 제공하는 솔루션입니다.</p>{' '}
        </blockquote>
      </Card.Body>
    </Card>

        </div>
        <div className="hosInfo_card">
        <Card style={{width: '100vmin', display:'flex', margin: 'auto'}}>
      <Card.Header><h5>주요기능</h5></Card.Header>
      <Card.Body>
      <Card.Title>
        {hosTitleData[currentIndex]}
      </Card.Title>
      <Stack direction="horizontal" gap={3} style={{justifyContent : "space-around"}}>
      <Button variant="outline-secondary" onClick={onClickLeft}>left</Button>
      <Card.Img variant="top" src= {hosImageData[currentIndex]}  
      style={{ width: '65vmin', height: '30vmax',display:'flex', margin: 'auto'}}/>
     <Button variant="outline-secondary"  onClick={onClickRigth}>right</Button>
      </Stack>
      </Card.Body>
    </Card>

          </div>

      </div>
    </div>
  )
}
