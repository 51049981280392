import React from 'react';
import './company.css'
import Header from "../../component/header";
import Footer from '../../component/footer';
import ComInfo from '../../component/Company/Company_info';

export default function Company() {
  return (
    <div className="company">
      <Header/>
      <ComInfo/>
      <Footer/>
    </div>
  )
}
