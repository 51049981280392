import React from 'react';
import './company_info.css'


export default function Company_info() {
  return (
    <div className="company_info">
    
      {/* <div className="company_info_header">
        <h2>회사소개</h2>
      </div> */}
      <div className="company_Greetings">
        <div className="greeting_title">
          <h2 >인사말</h2>
          </div>
          <div className="greting_detail">
            <p>안녕하십니까?</p>

            <p>제이에스정보통신은 IT 분야에 다양한 프로젝트 수행 경험과 기술력을 갖춘 최적화된 솔루션을 제공하는 기업입니다.</p>
   
            <p>2007년도에 설립하여 고객에게 고품질 서비스를 제공하며 지속적인 기술 개발과 혁신을 통해 사회에 기여할 수 있는 기업으로 성장해 가고 있습니다.</p>
       
            <p>특별히 진료 대기 안내 솔루션, 문자 서비스 솔루션의 소프트웨어 개발에 주력하고 있으며 업계의 선도적 역할을 수행할 수 있도록 꾸준한 노력으로 성실히 기업 활동을 영위하고 있습니다.
            </p>
        
            <p>아울러 제이에스정보통신은 봉사, 나눔, 섬김을 실천하고 시장을 선도하는 혁신적인 제품과 서비스로 차별화된 고객 가치를 제공하는 기업이 되도록 최선의 노력을 다하겠습니다.</p>

            <p>감사합니다.</p>

        </div>
        <div className="greting_sign">
          <p>대표이사 &nbsp; 정선희  &nbsp;</p>
          <img src="image/대표이사_사인.jpg" alt="정선희"/>
        </div>
      </div>
      
    
    </div>
  )
}
