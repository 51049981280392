import React from 'react';

import { BrowserRouter, Route, Switch } from "react-router-dom"
import Main from "./container/Main";
import Product_ms from "./container/Product/Pro_message"
import Product_hos from "./container/Product/Pro_hos"
import Product_call from "./container/Product/Pro_call"
import Business_hos  from './container/Business/Business_hospital';
import Business_080 from './container/Business/Business_080';
import Business_ms from './container/Business/Business_message';
import Company_gre from './container/Company/Company_gre'
import Company_map from './container/Company/Company_map'


export default function Routes() {

  return (
    <BrowserRouter>
    <Switch>
    <Route path="/" component={Main} exact />
    <Route path="/product_ms" component={Product_ms}/>
    <Route path="/product_hos" component={Product_hos}/>
    <Route path="/product_call" component={Product_call}/>
    <Route path="/business_hos" component={Business_hos}/>
    <Route path="/business_080" component={Business_080}/>
    <Route path="/business_ms" component={Business_ms}/>
    <Route path="/company_gre" component={Company_gre}/>
    <Route path="/company_map" component={Company_map}/>
    </Switch>
</BrowserRouter>
  )
}



