import React from 'react';
import './business.css'
import Header from "../../component/header";
import Footer from '../../component/footer';
import BusMSInfo from '../../component/Business/Business_messageInfo';

export default function Business_message() {
  return (
    <div className="business">
      <Header/>
      <BusMSInfo/>
      <Footer/>
    </div>
  )
}
