import React from 'react';
import './pro_message.css';
import Header from "../../component/header";
import Footer from '../../component/footer';
import Message_info from '../../component/Product/message_info';
export default function Pro_message() {
  return (
    <div className="pro_message">
      <Header/>
      <Message_info/>
      <Footer/>
    </div>
  )
}
