import React from 'react';
import './pro_hos.css';
import Header from "../../component/header";
import Footer from '../../component/footer';
import HosInfo from '../../component/Product/hos_info';

export default function Pro_hos() {
  return (
  <div className="pro_hos">
    <Header/>
    <HosInfo/>
    <Footer/>

  </div>
  )
}
