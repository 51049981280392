import React from 'react';
import './business_hos_info.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyData from '../db/Mydata.json';
import { Link } from 'react-router-dom';


export default function business_hos_info() {
  
  

  return (

   
    <div className="businessHos_info">
      <div className="businessHos_header">
        <h2>납품실적</h2>
        <div className="businessHos_table" role="navigation">
          <nav id="buisinessHos_nav">
          <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
          <Link to ="/business_hos">  <a id="hospital_res_nav"className="nav-link active">진료안내</a></Link>
          </li>
          <li className="nav-item">
          <Link to ="/business_ms"><a className="nav-link"
          style={{color:'black'}}>문자서비스</a></Link> 
          </li>
          {/* <li className="nav-item">
          <Link to ="/business_080"><a className="nav-link">080 출입명부</a></Link> 
          </li> */}
         
          </ul>
        </nav>
      </div>

      <div className="Hos_container">
      <div class="row row-cols-3">
      {MyData.Hospital.map((hospital) => (
         <div class="col">
           <img src={hospital.IMG} alt={hospital.name} id="hos_logo"/>
         
           </div>
      ))}

  </div>
    
    
</div>
      </div>
    </div>
  )
}