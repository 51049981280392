import React from 'react';
import './company.css'
import Header from "../../component/header";
import Footer from '../../component/footer';
import Company_info_map from '../../component/Company/Company_info_map';


export default function Company_map() {
  return (
    <div className="company_map">
      <Header/>
      <Company_info_map/>
      <Footer/>
    </div>
  )
}
