
import React from 'react';
import './business_message.css'
import MyData from '../db/Mydata.json';
import { Link } from 'react-router-dom';


export default function Business_messageInfo() {
  return (
    <div className="business_messageInfo">
       <div className="businessMs_header">
        <h2>납품실적</h2>
        <div className="businessMs_table" role="navigation">
          <nav id="buisinessMs_nav">
          <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
          <Link to ="/business_hos"> <a className="nav-link" style={{color:'black'}}>진료안내</a></Link>
          </li>
          <li className="nav-item">
          <Link to ="/business_ms"><a className="nav-link active" id="bus_active_btn" href="#" style={{color:'black'}}>문자서비스</a></Link>
          </li>
          {/* <li className="nav-item ">
          <Link to ="/business_080">  <a className="nav-link "href="">080 출입명부</a></Link>
          </li> */}
         
          </ul>
        </nav>
      </div>

      <div className="ms_container">
      <div class="row row-cols-3">
      {MyData.message.map((ms) => (
         <div class="col"><img src={ms.IMG} alt={ms.name} id="ms_logo"/>
         
         </div>
         
      ))}

  </div>
    
    
</div>
      </div>
    </div>
  )
}
