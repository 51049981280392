import React from 'react';
import './footer.css';

export default function footer() {
  return (

        <div className="footer" > 
        <div className="left">
         {/* <h2>JSinfo</h2> */}
         <img src="image\JSLogo_new2.png" alt=""/>
          {/* <p>제이에스정보통신</p> */}
        </div>
        <div className="right">
          <p> 주소 :  대구광역시 달서구 월배로34길 17 KT달서지사3층  / Tel  :  053)622-5160  / MP  :  010-5523-1489</p>
          <p> Fax : 053)622-5161 / E-Mail : ysj6772@daum.net </p>
          <p>Copyright 2022. 제이에스정보통신 Co. ALL Rights Reserved.</p>
        </div>
        </div>
  )
}
