import React from 'react';
import Header from "../component/header";
import MainInfo from "../component/Main_JSinfo";
// import MultiBox from '../component/MultiBox';
import Footer from '../component/footer';

import './Main.css';

export default function Main() {
  return (
    <div className='Main'>
     <Header />
      <MainInfo />
       <Footer/>
    </div>
   

  )
}
