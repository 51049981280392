/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import {Navbar,Container,Nav,NavDropdown} from "react-bootstrap"

export default function header() {

  
  return  ( 
  <div 
   className="header">

    <div className="gnb">
      <div className="logo">
        <Link to="/"> <img src="image/JSLogo_new1.png"
        alt="jsLogo" /></Link>
      </div>
      <div className="menu">
      <Navbar collapseOnSelect expand="lg" id="main_header">
  <Container>
 
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
    <Navbar.Text><Link to="/">홈</Link></Navbar.Text>
    <NavDropdown 
         title="회사소개" id="collasible-nav-dropdown"
         variant="outline-secondary">
       <NavDropdown.Item href="/company_gre">대표이사 인사말</NavDropdown.Item>
       <NavDropdown.Item href="/company_map">JS정보통신 오시는길</NavDropdown.Item>
         
      </NavDropdown>
    <NavDropdown title="제품소개" id="collasible-nav-dropdown"
          variant="outline-secondary">
              <NavDropdown.Item href="/product_hos">진료 안내</NavDropdown.Item>
        <NavDropdown.Item href="/product_ms">문자서비스</NavDropdown.Item>
  
        </NavDropdown>

        <NavDropdown 
         title="사업소개" id="collasible-nav-dropdown"
         variant="outline-secondary">
       <NavDropdown.Item href="/business_hos">납품실적</NavDropdown.Item>
        
      </NavDropdown>

    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
          </div>
        </div>

        <hr id="hr_style"></hr>
      

        </div>)
}