import React from 'react';
import './call_info.css';
import { Link } from 'react-router-dom';

export default function call_info() {
  return (
    <div className="call_info">
        <div className="callInfo_header">
        <h2>제품소개</h2>
         <div className="call_info_table" role="navigation">
          <nav id="call_info_nav">
          <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
          <Link to ="/product_ms"> <a className="nav-link ">문자서비스</a></Link>
          </li>
          <li className="nav-item">
          <Link to ="/product_hos"><a className="nav-link">진료안내</a></Link>
          </li>
          {/* <li className="nav-item ">
          <Link to ="/product_call">  <a className="nav-link active"   id="bus_active_btn">080 출입명부</a></Link>
          </li>
          */}
          </ul>
        </nav>
        </div>
      </div>
    
      <div className="callInfo_detail">
        <div className="call_left">
          <img src="proImage/0801.png" alt="080_1"/>
        </div>
        <div className="call_right">
        <img src="proImage/0802.png" alt="080_2"/>
        </div>
      </div>
    </div>
  )
}
