import React from 'react';
import './Main_JSinfo.css'
import {LocalHospital ,Call,Message }from "@material-ui/icons";

import { Link } from 'react-router-dom';
export default function Main_JSinfo() {
  return (
  <div className="Main_JSinfo">
    <div className="Main_content">
    <div className="main_h2">
      <h2>제이에스정보통신은</h2>
    </div>
    <div className="detail_JSinfo"  style={{marginTop:'10vh'}}>
    
      {/* <div className="info_call">
      <Link to ="/product_call">
      <svg id="second_icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
      </Link>
      <div><p>전화 출입 명부 서비스</p></div>
        </div> */}
        <div className="info_hospital">
      <Link to ="/product_hos"> <LocalHospital id="first_icon"/> </Link>
      <div><p>병원 진료 안내</p></div>
      </div>
    
     <div  className="info_message">

     <Link to ="/product_ms"><Message id="third_icon"/></Link>
     <div><p>문자 서비스</p></div>
      
     </div>
    </div>
    <div className="other_detail">
      <h2>외 다양한 서비스를 제공합니다.</h2>
      <Link to="/product_ms"><button type="button" id="gotoPro" class="btn btn-secondary" >제품소개 바로가기</button></Link>
 
    </div>
    </div>
  </div>
  )
}
